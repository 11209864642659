#top-left-name{
    color: white;
    font-size: 18px;
    padding-top: 16px;
    position: absolute;
    left: 64px;
    top: 0px;
}

.top-left-icon{
    width: auto;
    height: 24px;
    position: absolute;
    left: 24px;
    top: 16px;
}
.home-a{
    color: white;
    font-size: 18px;
    padding-top: 16px;
    position: absolute;
    right: 140px;
    top: 0px;
}
.contactus-a{
    color: white;
    font-size: 18px;
    padding-top: 16px;
    position: absolute;
    right: 24px;
    top: 0px;
}
.img-body{
    /* width: 100%; */
    /* position: relative; */
}

#app-desc-root{
    vertical-align:top;
    display: inline-block;
    position: relative;
    /* color: blanchedalmond; */
    width: 70%;
    height: auto;
    left: 5%;
    margin-top: 24px;
}
#big-main-phone{
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 8px;
}
.img-root{
    vertical-align:top;
    display: inline-block;
    position: relative;
    left: 4%;
    width: 20%;
    height: auto;
    /* top: -100px; */
    margin-top: 0px;
}
.bottom-link{
    margin: 0 auto;
    color: rgb(158, 165, 169);
    position: relative;
    margin-top: 24px;

    text-align: center;
    
}
.bottom-a{
    /* color: coral; */
    position: absolute;
    left: 50%;
    margin-left: 60px;
    top: 8px;
    font-style: italic ;
    font-size: 10px;
}
.bottom-b{
    /* color: coral; */
    position: absolute;
    left: 50%;
    font-style: italic ;
    margin-left: 160px;
    font-size: 10px;
    top: 8px;
}
.support-a{
    color: coral;
    font-size: 24px;
    position: absolute;
    left: 50%;
    margin-top: 12px;
    margin-left: 24px;
    z-index: 1;
}
.p-slogan{
    width: 92%;
    margin-left: 4%;
    text-align: center;
    font-size: 22px;
}
.download-img{
    width: 80%;
    margin-top: 8px;
}
.bottom-normal-left-text{
    position: absolute;
    right: 50%;
    margin-right: 60px;
    top: -2px;
    font-size: 10px;
}