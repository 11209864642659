p {
    margin-left: 24px;
    margin-right: 24px;
}

h1 {
    margin-left: 12px;
    margin-right: 12px;
}

h3 {
    margin-left: 18px;
    margin-right: 18px;
}

.p-detail {
    margin-left: 36px;
    margin-right: 36px;
}