.steps_root{
    position: relative;
    min-width: 800px;
    margin-left: 66px;
}
.arrow-right{
    position: relative;
    width: 56px;
    margin-left: 18px;
    margin-right: 18px;
}